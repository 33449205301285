import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
    <Layout>
        <SEO title="Contact" />

        <div className="font-sans text-sm m-auto">
            <form name="contact" action="/success" method="post" netlify-honeypot="bot-field" data-netlify="true">
                <input type="hidden" name="form-name" value="contact" />
                <h2 id="contact_form" className="text-xs uppercase mb-4">Contact</h2>
                <div class="grid grid-cols-2 gap-0">
                    <div>
                        <input id="input_name" name="name" placeholder="Your Name"
                            className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 py-2 px-4 block appearance-none leading-normal" />
                    </div>
                    <div>
                        <input id="input_email" name="email" type="email" placeholder="you@example.com"
                            className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 py-2 px-4 block appearance-none leading-normal" />
                    </div>
                </div>
                <div className="mb-8">
                    <textarea id="input_message" name="message" placeholder="Message..." rows="10"
                        className="bg-white w-full focus:outline-none focus:shadow-outline border border-gray-300 py-2 block appearance-none leading-normal" />
                </div>
                <div className="ml-auto mr-auto" style={{ width: 'fit-content' }}>
                    <button type="submit"
                        className="bg-transparent hover:bg-black text-black font-semibold hover:text-gray-400 py-2 px-4 border border-black hover:border-transparent rounded">
                        Send
                    </button>
                </div>
            </form>
        </div>
    </Layout>
)


export default IndexPage